import Select from "react-select";
import { Field, FieldRenderProps } from "tinacms";
import { Art } from "../../../common/lib/api/base";
import styles from "./CustomSelect.module.scss";

export type FEATURED_ART_INPUT_DATA<T> = {
  label: string;
  value: T;
  // [key: string]: any;
};

type FEATURED_ART_FIELD<T> = Field & {
  data: FEATURED_ART_INPUT_DATA<T>[];
  multi?: true;
};

const CustomSelect = <T extends any = Art>(
  props: FieldRenderProps<FEATURED_ART_INPUT_DATA<T>>
) => {
  const { input, meta, ...restProps } = props;
  const field = props.field as FEATURED_ART_FIELD<T>;
  const { value, ...rest } = input;
  return (
    <div className={styles.container}>
      {field.label && (
        <div>
          <label id="label">{field.label}</label>
        </div>
      )}
      <Select
        defaultValue={meta.initial}
        options={field.data}
        isMulti={field.multi}
        closeMenuOnSelect={!field.multi}
        {...restProps}
        {...rest}
        onChange={(val) => {
          input.onChange({
            target: {
              value: Array.isArray(val)
                ? val
                : // @ts-ignore
                  val?.value,
              id: field.name,
              name: field.name,
            },
          });
        }}
      />
    </div>
  );
};

export default {
  Component: CustomSelect,
  name: "custom-select",
};
