import { useCMS } from "tinacms";
import FeaturedArtSelect from "./CustomSelect";
import TagsSelect from "./TagsSelect";
import CarouselSelect from "./CarouselSelect";
const usePlugins = () => {
  const cms = useCMS();
  cms.fields.add(FeaturedArtSelect);
  cms.fields.add(TagsSelect);
  cms.fields.add(CarouselSelect);
};

export { usePlugins };
