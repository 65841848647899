import { doc, query, where } from "@firebase/firestore";
import { Art, BaseAPI, SpaceshipFirebase } from "./base";

class Arts extends BaseAPI<Art> {
  constructor(firestore: SpaceshipFirebase) {
    super(firestore, "arts");
  }
  arts = () => {
    return this.getCollection();
  };
  art = (docId: string) => {
    return this.getById(docId);
  };
  artAt = (docId: string) => {
    return doc(this.getCollection(), docId);
  };
  featuredArtRef = () => {
    return query(this.arts(), where("featured", "==", true));
  };
}

export { Arts };
