import { QuerySnapshot } from "@firebase/firestore";
import cnames from "classnames";
import { GetStaticPropsContext } from "next";
import { AuthUserContext } from "next-firebase-auth";
import { MouseEventHandler } from "react";
import { Art, LOCALE_OPTIONS, PATHS, supportedLocales } from "./api/base";
type COMPARE_FUNC<T> = {
  (a: T, b: T): boolean;
};

const eq: COMPARE_FUNC<any> = (a: any, b: any) => {
  return a === b;
};

export const togglePush = <T extends any>(
  arr: T[],
  item: T,
  compare: COMPARE_FUNC<T> = eq
) => {
  if (arr.includes(item)) {
    const arrIndex = arr.findIndex((sel) => sel === item);
    const newArray = [...arr];
    newArray.splice(arrIndex, 1);
    return newArray;
  }
  return [...arr, item];
};

export const picsumUrl = () =>
  `https://picsum.photos/${800 + Math.floor(5 * Math.random()) * 100}/${
    800 + Math.floor(5 * Math.random()) * 100
  }`;

export const toDocs = <T extends {}>(query: Promise<any>) => {
  return query.then((snap: QuerySnapshot) => {
    if (snap.empty || snap.empty === undefined) return [];
    return snap.docs.map((doc: any) => ({
      ...doc.data(),
      id: doc.id,
    })) as unknown as T[];
  });
};

export const toNatural = (str: string) =>
  str.replace(/(?:^|_)(\w)/g, (match: string, g1: string) => {
    if (match.includes("_")) {
      return " " + g1.toUpperCase();
    }
    return g1.toUpperCase();
  });

export const isVideo = (url: string | string[]) =>
  !Array.isArray(url) && /(.*).(wav|mp4|mov|webm)$/i.test(url);

export const getEthRatesFor = async (ticker: string) => {
  const { default: fetch } = await import("node-fetch");
  const BASE_URL = "https://pro-api.coinmarketcap.com/v1/cryptocurrency";
  return fetch(`${BASE_URL}/quotes/latest?symbol=ETH&convert=${ticker}`, {
    headers: {
      ["X-CMC_PRO_API_KEY"]: process.env.COINCAP_API_KEY as string,
    },
  }).then((r) => {
    return r.json();
  });
};

export const RATES_FILE_PATH = "./rates.json";
export const toDoubleDigits = (num: number) => ("00" + num).slice(-2);
// Ties to collection.arts[index]'s type
type ART_ASSETS = Art;

export const getArtUrl = (art: ART_ASSETS) => {
  const { image_url, animation_url } = art;
  if (animation_url) return animation_url;
  return image_url;
};

export const getArtPreviewUrl = (art: ART_ASSETS, showThumbnail?: boolean) => {
  const { image_preview_url, animation_preview_url } = art;
  if (showThumbnail) return image_preview_url;
  if (animation_preview_url) return animation_preview_url;
  return image_preview_url;
};

export const getArtLowQualUrl = (art: ART_ASSETS) => {
  const { image_thumbnail_url } = art;
  return image_thumbnail_url;
};

export const getArtOriginalUrl = (art: ART_ASSETS) => {
  const { image_original_url, animation_original_url } = art;
  if (animation_original_url) return animation_original_url;
  return image_original_url;
};

const units = [60, 60, 24];
const unitNames = [" s", " min", " h", " d"];
const getUnit = (str: string) => {
  return unitNames[str.split(":").length - 1];
};

export const fromSeconds = (duration: number) => {
  let upper,
    lower,
    result = "",
    remaining = duration;
  for (let unit of units) {
    lower = remaining % unit;
    upper = Math.trunc(remaining / unit);
    result = toDoubleDigits(lower) + result;
    if (upper > 0) result = ":" + result;
    remaining = upper;
    if (remaining === 0) break;
  }
  return result + getUnit(result);
};

export type MapOf<D> = {
  [key in string | number]: D;
};
type CS_TYPE = {
  (styles: MapOf<string>, classNames: MapOf<boolean>): string;
  (
    styles: MapOf<string>,
    classNames: string[],
    optional?: MapOf<boolean>
  ): string;
};

export const cs: CS_TYPE = (
  styles: any,
  classNames: any,
  optional: any = {}
) => {
  if (Array.isArray(classNames)) {
    return classNames
      .map((name) => styles[name])
      .concat(
        Object.entries(optional)
          .map(([name, include]) => (include ? styles[name] : undefined))
          .filter(Boolean)
      )
      .join(" ");
  }
  const entries = Object.entries(classNames);
  const notInStyles = Object.fromEntries(
    entries.filter(([key]) => !(key in styles))
  );
  return Object.entries(classNames)
    .map(([name, include]) => (include ? styles[name] : undefined))
    .concat(cnames(notInStyles))
    .filter(Boolean)
    .join(" ");
};

export const prettifyWalletHash = (hash: string = "") =>
  hash.slice(0, 5) + "..." + hash.slice(-2);

export const toCamelCase = (str: string) =>
  str.replace(/_(\w)/g, (_, g1) => `${g1.toUpperCase()}`);

export const toJSON = <T extends MapOf<any>>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj)) as T;
};

export const makeStaticPathsLocale = (paths: any[]) =>
  paths.reduce((prev, curr) => {
    supportedLocales.forEach((locale) => {
      prev.push({ ...curr, locale });
    });
    return prev;
  }, [] as PATHS);

export const isAdmin = (user: AuthUserContext) => {
  return user.firebaseUser && !user.firebaseUser.isAnonymous;
};
type REMOVE_INVALID_VALUES_TYPE = {
  <T>(obj: T): Partial<T>;
};
export const removeInvalidValues: REMOVE_INVALID_VALUES_TYPE = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, val]) => {
      if (typeof val === "string" && val.length === 0) return false;
      if (val === null) return false;
      if (val === undefined) return false;
      return true;
    })
  ) as any;
};

export const getLocaleFromStaticContext = (ctx: GetStaticPropsContext) => {
  const { locale: baseLocale } = ctx;
  const locale: LOCALE_OPTIONS = (baseLocale || "en") as LOCALE_OPTIONS;
  return locale;
};

export const handleChangeLocale: MouseEventHandler<HTMLAnchorElement> = (e) => {
  e.preventDefault();
  // Remove query from parent <a> element
  // @ts-ignore
  const target = e.target as HTMLAnchorElement;
  window.location.href = (
    target?.getAttribute("href") ||
    target?.parentElement?.getAttribute("href") ||
    ""
  ).replace(/\?.*/, "");
};

var pr = new Intl.PluralRules("en-US", { type: "ordinal" });

const suffixesEn = new Map([
  ["one", "st"],
  ["two", "nd"],
  ["few", "rd"],
  ["other", "th"],
]);
export const formatOrdinals = (n: number) => {
  const rule = pr.select(n);
  const suffix = suffixesEn.get(rule);
  return `${n}${suffix}`;
};

export const generateThumbnail = (file: any) => {
  console.log(`Trying to generate thumbnail`, file);
  var url = URL.createObjectURL(file);
  var canvas = document.createElement("canvas");
  var ctx = canvas.getContext("2d");

  return new Promise((resolve, reject) => {
    var video = document.createElement(`video`);
    video.addEventListener(`loadedmetadata`, function () {
      video.currentTime = 0.1;
      video.addEventListener(`timeupdate`, () => {
        let w = video.videoWidth;
        let h = video.videoHeight;
        canvas.width = w;
        canvas.height = h;
        ctx?.drawImage(video, 0, 0, w, h);
        canvas.toBlob((blob) => {
          console.log(blob);
          resolve(blob);
        }, "image/jpeg");
      });
    });
    video.src = url;
  }).then((result) => {
    URL.revokeObjectURL(file);
    return result;
  });
};

export const ipfsToHttps = (url: string) =>
  url.replace("ipfs://", "https://ipfs.io/ipfs/");

export const fetchIpfs = (url: string) => {
  let urlToFetch = url;
  if (urlToFetch.startsWith("ipfs://")) {
    urlToFetch = urlToFetch.replace("ipfs://", "https://ipfs.io/ipfs/");
  }
  // console.log({ urlToFetch });
  return fetch(urlToFetch).then((r) => r.json());
};
