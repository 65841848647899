import { createContext, useContext, useEffect, useState } from "react";
import { EXTRA_LARGE, LARGE, MEDIUM, MOBILE } from "../constants";

type DIMENSIONS =
  | {
      width: number;
      height: number;
    }
  | undefined;

const WINDOW_CONTEXT_DEFAULTS: DIMENSIONS = {
  width: 1080,
  height: 1920,
};

const WindowContext = createContext<DIMENSIONS>(WINDOW_CONTEXT_DEFAULTS);

const useWindowChange = () => {
  const [dimensions, setDimensions] = useState<DIMENSIONS>(
    WINDOW_CONTEXT_DEFAULTS
  );
  useEffect(() => {
    const handler = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
    handler();
    window.addEventListener("resize", handler);
    return () => window.removeEventListener("resize", handler);
  }, []);
  return dimensions;
};

const WindowContextProvider: React.FC = (props) => {
  const window = useWindowChange();
  const CtxProvider = WindowContext.Provider;
  return <CtxProvider value={window} {...props} />;
};

const useWindow = () => useContext(WindowContext);

type BREAKPOINTS = "MOBILE" | "MEDIUM" | "LARGE" | "EXTRA_LARGE";

const constants: { [key in BREAKPOINTS]: number } = {
  EXTRA_LARGE,
  LARGE,
  MEDIUM,
  MOBILE,
};

const useBreakpoint = (bp: BREAKPOINTS) => {
  const window = useWindow();
  return !!(window && window.width > constants[bp]);
};
export { useWindow, useBreakpoint, WindowContextProvider };
