import styled, { css } from "styled-components";
import { BLACK_BG, medium, mobileOnly, WHITE_BG } from "../../styles/mixins";

export const Footer = styled.footer`
  width: 100%;
  background-color: ${WHITE_BG};
  color: ${BLACK_BG};
  padding: 0.5em;
  ${medium(css`
    padding: 0.75em 1.25em;
  `)}
  isolation: isolate;
  z-index: 2;
  justify-content: space-between;
  border-top: 0.5px solid ${BLACK_BG};
`;

export const Sections = styled.section`
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
  ${medium(css`
    flex-direction: row;
    margin-bottom: 2em;
    > div {
      align-items: unset;
      margin-right: 8em;
    }
  `)}
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      font-size: 1.5em;
      margin-bottom: 0.5em;
    }
  }
  span {
    opacity: 0.6;
  }
`;

export const Copyright = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${mobileOnly(css`
    .icons {
      font-size: 1em;
    }
    .logo img {
      height: 2em;
    }
  `)}
  .icons {
    display: flex;
    align-items: center;
    font-size: 1.5em;
    margin-top: 0.25em;
    ${medium(css`
      margin-top: 0.5em;
    `)}
  }
  .logo img {
    height: 2.5em;
  }
  a {
    display: flex;
    align-items: center;
  }
  * + * {
    margin-left: 0.75em;
  }
  svg {
    margin-top: 0.1em;
  }
  h3 {
    margin: 0;
    font-family: "Bebas Neue Pro Expanded";
    margin-bottom: 0.1em;
    font-weight: 400;
    letter-spacing: 0.8px;
    ${medium(css`
      font-size: 0.85em;
    `)}
  }
`;

export const CopyText = styled.p`
  font-size: 0.8em;
  opacity: 0.4;
  margin-right: 0;
  margin-left: 0;
`;
