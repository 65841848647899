import DataLoader from "dataloader";
import { toDocs } from "../utils";
import { AssetContract } from "../../..";
import {
  collection,
  CollectionReference,
  Firestore,
  getDocs,
  query,
  Query,
  where,
  doc,
  WhereFilterOp,
} from "@firebase/firestore";
class BaseAPI<T> {
  store: SpaceshipFirebase;
  base: string;
  itemLoader: DataLoader<string, T, string>;
  constructor(firestore: SpaceshipFirebase, base: string = "") {
    this.store = firestore;
    this.base = base;
    const itemLoader = new DataLoader<string, T, string>(this.batchGetById);
    this.itemLoader = itemLoader;
  }
  public getFromPath = <T extends Query<any>>(path: string) => {
    const parts = path.split("/");
    // Path is collection/doc/collection/doc/...
    //              0      1      2      3
    return parts.reduce<any>((prev, curr, i) => {
      // 0 2 4 6
      if (i % 2 === 0) {
        return collection(prev, curr);
        // 1 3 5 7
      } else {
        return doc(prev, curr);
      }
    }, this.store) as T;
  };
  getCollection = () => {
    return collection(this.store, this.base);
  };
  where = (...args: [string, WhereFilterOp, any]) =>
    getDocs(query(collection(this.store, this.base), where(...args)));

  getAll = (transformQuery: (ref: CollectionReference) => Query = (i) => i) => {
    const q = transformQuery(this.getCollection());
    return toDocs(getDocs(q)) as Promise<T[]>;
  };
  batchGetById = (ids: readonly string[]) => {
    return getDocs(query(this.getCollection(), where("id", "in", ids))).then(
      (snap) => {
        return ids.map((id) => {
          const data = snap.docs.find((doc) => doc.data().id === id)?.data();
          return data;
        }) as T[];
      }
    );
  };
  getById = (id: string) => {
    return this.itemLoader.load(id);
  };
  getByTags = (tags: string[]) => {
    return toDocs(
      getDocs(
        query(this.getCollection(), where("tags", "array-contains-any", tags))
      )
    );
  };
}

export { BaseAPI };
export type SpaceshipFirebase = Firestore;
export type Collection = Overridable<{
  id: string;
  tags?: Tags[];
  featured_arts?: [Art, Art, Art];
  wallet_address?: string;
  show_on_main_page?: boolean;
  banner_image_url: string;
  chat_url: string;
  created_date: string;
  default_to_fiat: boolean;
  description: string;
  dev_buyer_fee_basis_points: string;
  dev_seller_fee_basis_points: string;
  discord_url: string;
  // display_data: { card_display_style: string },
  external_url: string;
  featured: boolean;
  featured_image_url: string;
  hidden: boolean;
  safelist_request_status: string;
  image_url: string;
  is_subject_to_whitelist: boolean;
  large_image_url: string;
  medium_username: string;
  name: string;
  only_proxied_transfers: boolean;
  opensea_buyer_fee_basis_points: string;
  opensea_seller_fee_basis_points: string;
  payout_address: string;
  require_email: boolean;
  short_description: string;
  slug: string;
  telegram_url: string;
  twitter_username: string;
  instagram_username: string;
  wiki_url: string;
  // arts: Required<
  //   Pick<
  //     Art,
  //     | "id"
  //     | "name"
  //     | "image_url"
  //     | "image_preview_url"
  //     | "image_thumbnail_url"
  //     | "image_original_url"
  //     | "animation_original_url"
  //     | "animation_url"
  //     | "animation_preview_url"
  //     | "slug"
  //   >
  // >[];
}>;
export const supportedLocales = ["en", "pt"] as const;
export type LOCALE_OPTIONS = typeof supportedLocales[number];
export type Overridable<T, D extends keyof T | "" = ""> = T & {
  overrides?: {
    [Y in LOCALE_OPTIONS]: Partial<Omit<T, D>>;
  };
};

export type Nullable<T> = {
  [D in keyof T]: T[D] | null;
};

export type Trait = {
  display_type: string | null;
  max_value: number | null;
  order: number | null;
  trait_count: number;
  trait_type: string;
  value: string;
};

export type Indexable<T> = {
  [D in keyof T]: T[D];
};
type Tags = {
  value: string;
  label: string;
};
export type Art = Overridable<
  {
    tags?: Tags[];
    order: number;
    id: string;
    reserve_price?: string;
    slug?: string;
    token_id: string;
    num_sales: number;
    background_color: string;
    image_url: string;
    image_preview_url: string;
    image_thumbnail_url: string;
    image_original_url: string;
    animation_url: string;
    animation_original_url: string;
    animation_preview_url: string;
    cloudinary_public_id?: string;
    name: string;
    description: string;
    external_link: string;
    asset_contract: AssetContract;
    owner: any[];
    permalink: string;
    collection: Collection;
    decimals: number;
    token_metadata: any;
    creator: any[];
    traits?: Trait[];
    last_sale: number | Date;
    top_bid: string;
    listing_date: number | Date;
    is_presale: boolean;
    transfer_fee_payment_token: string;
    transfer_fee: number;
    sell_orders?: Order[];
    highest_bid?: Order;
    alt_text?: string;
    audio_description?: string;
    mainpage_auction?: string;
  },
  "sell_orders" | "highest_bid"
>;

export type Order = {
  payment_token_contract: {
    address: string;
    usd_price: string;
    symbol: string;
    decimals: number;
    image_url: string;
    eth_price: string;
    id: number;
    name: string;
  };
  asset?: Art;
  approved_on_chain: boolean;
  target: string;
  maker_protocol_fee: string;
  current_bounty: string;
  exchange: string;
  finalized: boolean;
  expiration_time: number;
  taker_protocol_fee: string;
  payment_token: string;
  maker: {
    profile_img_url: string;
    discord_id: string;
    config: string;
    user: number;
    address: string;
  };
  how_to_call: number;
  closing_date: string;
  fee_recipient: {
    config: string;
    profile_img_url: string;
    address: string;
    user: number;
    discord_id: string;
  };
  static_target: string;
  replacement_pattern: string;
  bounty_multiple: string;
  extra: string;
  calldata: string;
  cancelled: boolean;
  taker: {
    user: number;
    config: string;
    profile_img_url: string;
    discord_id: string;
    address: string;
  };
  side: number;
  sale_kind: number;
  marked_invalid: boolean;
  closing_extendable: boolean;
  fee_method: number;
  base_price: string;
  maker_relayer_fee: string;
  maker_referrer_fee: string;
  prefixed_hash: string;
  metadata: {
    schema: string;
    asset: {
      quantity: string;
      address: string;
      id: string;
    };
  };
  static_extradata: string;
  current_price: string;
  listing_time: number;
  salt: string;
  created_date: string;
  taker_relayer_fee: string;
  order_hash: string;
  quantity: string;
};

export type CRYPTO_LIST = "ETH";
export type RATES_TYPE = CRYPTO_RATES & {
  fetchedAt?: number;
};

declare namespace Express {
  export interface Request {
    firebase?: FirebaseFirestore.Firestore;
  }
}
export type CRYPTO_RATES = {
  [key in CRYPTO_LIST]: {
    id?: number;
    name?: string;
    symbol?: string;
    slug?: string;
    num_market_pairs?: number;
    date_added?: string;
    tags?: string[];
    max_supply?: number;
    circulating_supply?: number;
    total_supply?: number;
    is_active?: number;
    platform?: string;
    cmc_rank?: number;
    is_fiat?: number;
    last_updated?: string;
    quote: {
      [fiat: string]: {
        price: number;
        volume_24h?: number;
        percent_change_1h?: number;
        percent_change_24h?: number;
        percent_change_7d?: number;
        percent_change_30d?: number;
        percent_change_60d?: number;
        percent_change_90d?: number;
        market_cap?: number;
        last_updated?: string;
      };
    };
  };
};

export const TYPE_ARRAY = ["post", "image"] as const;

export type CAROUSEL_ITEM = {
  type: typeof TYPE_ARRAY[number];
  content: {
    [key: string]: any;
  };
};
export type PATHS = Array<{ params: { slug: string }; locale: LOCALE_OPTIONS }>;

export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Long` scalar type represents 52-bit integers */
  Long: any;
  /** A time string with format: HH:mm:ss.SSS */
  Time: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type UploadFile = {
  __typename?: "UploadFile";
  id: Scalars["ID"];
  created_at: Scalars["DateTime"];
  updated_at: Scalars["DateTime"];
  name: Scalars["String"];
  alternativeText?: Maybe<Scalars["String"]>;
  caption?: Maybe<Scalars["String"]>;
  width?: Maybe<Scalars["Int"]>;
  height?: Maybe<Scalars["Int"]>;
  formats?: Maybe<Scalars["JSON"]>;
  hash: Scalars["String"];
  ext?: Maybe<Scalars["String"]>;
  mime: Scalars["String"];
  size: Scalars["Float"];
  url: Scalars["String"];
  previewUrl?: Maybe<Scalars["String"]>;
  provider: Scalars["String"];
  provider_metadata?: Maybe<Scalars["JSON"]>;
};

type Maybe<T> = T | null;

type NonMaybeArray<T> = T extends Array<Maybe<infer G>>
  ? NonNullable<Array<G>>
  : NonNullable<T>;

type NonMaybeField<T> = T extends Maybe<infer D>
  ? NonMaybeArray<D>
  : NonMaybeArray<T>;

// prettier-ignore
export type NonMaybe<T> =
  // If Type is Array of something => Remove inner Maybe
  T extends Array<infer D>
    ? Array<NonMaybe<D>>
    // Type is object => For each key run recursive
    : T extends { [key: string]: any }
    ? {
        [K in keyof T]: NonMaybe<T[K]>;
      }
    // Not object or array, return cleaned field
    : NonMaybeField<T>;
export type LOCALE_LINK_OVERRIDES = {
  [key in LOCALE_OPTIONS]?: string;
};
