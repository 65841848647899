import dayjs from "dayjs";
import "dayjs/locale/pt";
import AdvancedFormatPlugin from "dayjs/plugin/advancedFormat";
import CalendarPlugin from "dayjs/plugin/calendar";
import ParseFormatPlugin from "dayjs/plugin/customParseFormat";
import { useTranslations } from "next-intl";
import { DefaultSeo } from "next-seo";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { supportedLocales } from "../lib/api/base";
import { BLACK_BG_ALT, WHITE_BG } from "../styles/mixins";

dayjs.extend(CalendarPlugin);
dayjs.extend(ParseFormatPlugin);
dayjs.extend(AdvancedFormatPlugin);

const commonButtonStyles = {
  border: "none",
  fontSize: "1em",
  borderRadius: 3,
};
export const AppHead: React.FC<{ router: ReturnType<typeof useRouter> }> = ({
  router,
}) => {
  const [consentValue, setConsentValue] = useState(false);
  const { asPath, locale } = router;
  const canonicalUrl = `https://spaceshipnft.com${asPath}`;
  const isDev = process.env.NODE_ENV === "development";
  const t = useTranslations("Cookies");
  useEffect(() => {
    const consentString = getCookieConsentValue();
    const hasGivenConsent = consentString ? consentString === "true" : false;
    setConsentValue(hasGivenConsent);
  }, []);
  return (
    <>
      <DefaultSeo
        titleTemplate={`%s - Spaceship NFT Art Gallery`}
        defaultTitle={`Spaceship NFT Art Gallery`}
        description="Spaceship is an NFT Art Gallery where the focus is on the artist. We aim to provide the highest quality art works as well as high-quality curatorship to our collaborators"
        twitter={{
          cardType: "summary_large_image",
          site: "@spaceshipnft",
          handle: "@spaceshipnft",
        }}
        additionalLinkTags={[
          {
            rel: "icon",
            href: "/spaceship_icon_dynamic.svg",
          },
        ]}
      />
      <Head>
        {supportedLocales
          .filter((incLocale) => incLocale !== locale)
          .map((locale) => (
            <link
              rel="alternate"
              hrefLang={locale}
              key={locale}
              href={`https://spaceshipnft.com/${locale}${asPath}`}
            />
          ))}
        <link rel="alternate" hrefLang={"x-default"} href={canonicalUrl} />
        <link rel="canonical" href={canonicalUrl} />
        <link rel="stylesheet" href="/fonts/bebas/stylesheet.css" />
        <script
          src="https://upload-widget.cloudinary.com/global/all.js"
          type="text/javascript"
        ></script>
        {consentValue && (
          <script>{`
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
`}</script>
        )}
      </Head>
      <CookieConsent
        location="bottom"
        buttonText={t("accept")}
        declineButtonText={t("decline")}
        enableDeclineButton
        flipButtons
        style={{ background: BLACK_BG_ALT }}
        buttonStyle={{
          backgroundColor: WHITE_BG,
          color: "black",
          ...commonButtonStyles,
        }}
        declineButtonStyle={{ ...commonButtonStyles }}
        expires={150}
        debug={isDev}
        onAccept={() => {
          setConsentValue(true);
        }}
        onDecline={() => {
          setConsentValue(false);
        }}
      >
        {t("message")}
      </CookieConsent>
      {consentValue && (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
      )}
    </>
  );
};
