import styled from "styled-components";
import { px, WHITE_BG } from "../../styles/mixins";
const RATIO = 1.7;
const size = 36;
export const Wrapper = styled.div`
  position: fixed;
  bottom: 12px;
  right: 12px;
  filter: drop-shadow(1px 1px 3px rgba(0 0 0 /80%));
  button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${WHITE_BG};
    height: ${px(size * Math.sqrt(RATIO))};
    width: ${px(size * Math.sqrt(RATIO))};
    padding: 0;
    border: none;
    border-radius: ${px(size * Math.sqrt(RATIO))};
  }
  span {
    font-size: 1.7em;
  }
`;
