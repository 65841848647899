import React, { Component, FC } from "react";
import CreatableSelect from "react-select/creatable";
import { FieldRenderProps } from "tinacms";
import { toNatural } from "../../../common/lib/utils";

const components = {
  DropdownIndicator: null,
};

const createOption = (label: string) => ({
  label,
  value: label,
});

class CreatableInputOnly extends Component<FieldRenderProps<any>> {
  state = {
    inputValue: "",
    value: [] as {
      label: string;
      value: string;
    }[],
  };
  constructor(props: FieldRenderProps<any>) {
    super(props);
    this.state.value = props.field.defaultValue;
  }
  handleChange = (value: any, actionMeta: any) => {
    this.setState({ value });
  };
  componentDidUpdate = (_: any, prevState: any) => {
    if (prevState.value.length !== this.state.value.length)
      this.props.input.onChange({
        target: {
          value: this.state.value,
          id: this.props.field.name,
          name: this.props.field.name,
        },
      });
  };
  handleInputChange = (inputValue: string) => {
    this.setState({ inputValue });
  };
  handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    const { inputValue, value } = this.state;
    const alreadyInState =
      value.findIndex((arrItem) => arrItem.value === inputValue) !== -1;
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        this.setState({
          inputValue: "",
          value: alreadyInState ? value : [...value, createOption(inputValue)],
        });
        event.preventDefault();
    }
  };
  render() {
    const { inputValue, value } = this.state;
    const { input } = this.props;
    return (
      <div style={{ marginBottom: "1em" }}>
        <label htmlFor={this.props.field.name} id="label">
          {toNatural(this.props.field.name)}
        </label>
        <CreatableSelect
          components={components}
          inputValue={inputValue}
          isClearable
          isMulti
          menuIsOpen={false}
          {...input}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          onKeyDown={this.handleKeyDown}
          placeholder="Type something and press enter..."
          value={value}
        />
      </div>
    );
  }
}

export default {
  Component: CreatableInputOnly as unknown as FC,
  name: "tags",
};
