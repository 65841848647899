import styled, { css } from "styled-components";

type Rule = ReturnType<typeof css>;
type Value = string | number;

export const px = (base: string | number) =>
  !Boolean(Number(base)) ? base : base + "px";

// max-width media query shorthand
export const mediaMax = (width: string | number, rule: Rule) =>
  css`
    @media screen and (max-width: ${px(width)}) {
      ${rule}
    }
  `;

// min-width media query shorthand
export const mediaMin = (width: string | number, rule: Rule) =>
  css`
    @media screen and (min-width: ${px(width)}) {
      ${rule}
    }
  `;

// Breakpoints go mobile-up
// mobile only is for smaller than medium

export const mobileOnly = (rule: Rule, width: Value = 768) => css`
  ${mediaMax(width, rule)}
`;

export const medium = (rule: Rule, width: Value = 769) => css`
  ${mediaMin(width, rule)}
`;

export const large = (rule: Rule, width: Value = 1025) => medium(rule, width);

export const extraLarge = (rule: Rule, width: Value = 1301) =>
  medium(rule, width);

// constants
export const BOX_SHADOW =
  "0 0 0.1px 2px white, 0 0 40.1px 2px rgba(192 219 255 / 48%), 0 0 22.35px 2px rgb(65 120 255 / 24%);";
export const BOX_SHADOW_SMALL =
  "0 0 0.1px 2px white, 0 0 15.1px 2px rgba(192 219 255 / 48%), 0 0 11.1px 2px rgb(65 120 255 / 24%);";

export const WHITE_BG = "rgba(224 225 229/  100%)";
export const BLACK_BG = "rgba(25 27 31/ 100%)";
export const BLACK_BG_ALT = "rgba(41 43 49/ 100%)";
export const ACCENT_COLOR = "hsl(223, 67%, 92%)"; // hsl(174deg 57% 93%); //
export const HEADER_HEIGHT_MOBILE = 95;
export const HEADER_HEIGHT_MEDIUM = 110;
// Glow
export const glowBoxShadow = (isOnHover: boolean = false) => {
  const shadowCss = isOnHover
    ? css`
        box-shadow: 0 0 0.1px 2px white;
        &:hover,
        &:focus-within {
          box-shadow: ${BOX_SHADOW_SMALL};
          ${medium(css`
            box-shadow: ${BOX_SHADOW};
          `)}
        }
      `
    : css`
        box-shadow: ${BOX_SHADOW_SMALL};
        ${medium(css`
          box-shadow: ${BOX_SHADOW};
        `)}
      `;
  return css`
    overflow: hidden;
    border-radius: 5px;
    ${shadowCss}
    &.off {
      box-shadow: 0 0 0.1px 2px white;
    }
  `;
};

export const glow = (isOnHover: boolean = false) => css`
  .glow {
    ${glowBoxShadow(isOnHover)}
  }
`;

// Layout
export const content = ({
  medium = 768,
  large = 1024,
  extraLarge = 1300,
  noPadding = false,
} = {}) => {
  // prettier-ignore
  const smalllViewportRule =
    mediaMin(
      medium,
      css`
        max-width: calc(${px(medium)} - 28px);
      `
    );
  const largeViewportRule =
    large > medium &&
    mediaMin(
      large,
      css`
        max-width: calc(${px(large)} - 36px);
      `
    );
  const extraLargeViewportRule =
    extraLarge > large &&
    mediaMin(
      extraLarge,
      css`
        max-width: calc(${px(extraLarge)} - 48px);
      `
    );
  return css`
    ${smalllViewportRule}
    ${largeViewportRule}
    ${extraLargeViewportRule}
    z-index: 0;
    width: 90%;
    margin: auto;
    // background-color: rgba(255 0 0 /0.2);
    ${!noPadding ? `padding-bottom: 42px;` : ""}
  `;
};

// Text
export const bottomText = () => css`
  position: relative;
  span:first-child {
    opacity: 0.7;
    font-size: 0.7em;
    position: absolute;
    bottom: -1.4ch;
    display: inline-flex;
    align-items: center;
  }
`;

export const topText = () => css`
  position: relative;
  span:first-child {
    opacity: 0.7;
    font-size: 0.7em;
    position: absolute;
    top: -1.4ch;
    display: inline-flex;
    align-items: center;
  }
`;

export const biggerFirstLetter = (fontSize: Value = "1em") => css`
  display: inline-block;
  font-size: ${px(fontSize)};
  & + & {
    margin-left: 1ch;
  }
  &::first-letter {
    font-size: 1.15em;
  }
`;

const minus = (val: string) => (val.startsWith("-") ? val.slice(1) : `-${val}`);

const degToTan = (str: string) =>
  Math.abs(Math.tan((Number(str.replace("deg", "")) * Math.PI) / 180)).toFixed(
    2
  );

export const skewDelta = (angle: string) => `calc(${degToTan(angle)} * 50vw)`;

export const skew = (angle: string, noContent?: boolean) => css`
  transform: skew(${angle}) rotate(${angle});
  & > *:only-child {
    transform: skew(${minus(angle)}) rotate(${minus(angle)});
    padding-top: ${skewDelta(angle)};
    padding-bottom: ${skewDelta(angle)};
    ${!noContent ? content({ noPadding: true }) : ""}
  }
`;

export const glass = (padding = "1.25em") => css`
  padding: ${px(padding)};
  backdrop-filter: blur(4px);
  border-radius: 6px;
  background-color: rgba(0 0 0 / 35%);
`;

export const makeGlass = (c: any, padding = "1.25em") => styled(c)`
  ${glass(padding)}
`;
