import { useAuthUser, withAuthUser } from "next-firebase-auth";
import { useEffect } from "react";
import { useCMS } from "tinacms";
import { isAdmin } from "../../lib/utils";

const CmsEnablerComponent = () => {
  const user = useAuthUser();
  const cms = useCMS();
  useEffect(() => {
    if (isAdmin(user)) {
      cms.enable();
    } else if (cms.enabled) {
      cms.disable();
    }
  }, [user, cms, user.firebaseUser]);
  return null;
};

export const CmsEnabler = withAuthUser()(CmsEnablerComponent);
