import { collection, doc, getDoc } from "@firebase/firestore";
import { BaseAPI, SpaceshipFirebase, Collection } from "./base";
class CollectionsApi extends BaseAPI<Collection> {
  constructor(firestore: SpaceshipFirebase) {
    super(firestore, "collections");
  }
  collections() {
    return this.getCollection();
  }
  collection(docId: string) {
    return this.getById(docId);
  }
  collectionAt(docId: string) {
    return doc(this.collections(), docId);
  }
  featuredArts(docId: string, locale?: string) {
    return collection(this.collectionAt(docId), "featured_arts");
  }
  featruredArtAt(docId: string, locale: string, artId: string) {
    return doc(this.featuredArts(docId, locale), artId);
  }
  featuredArt(docId: string, locale: string, artId: string) {
    return getDoc(this.featruredArtAt(docId, locale, artId)).then((r) => {
      if (r.exists()) return r.data();
      return Promise.reject("Not found");
    });
  }
}

export const Collections = CollectionsApi;
