import en from "./en.json";
import pt from "./pt.json";

const translations = {
  en,
  pt,
};

export { en, pt };
export default translations as {
  [T in keyof typeof translations]: typeof translations[T];
};
export type TranslationIndex = keyof typeof translations;
