import Airtable from "airtable";
import dayjs from "dayjs";
import { NextIntlProvider } from "next-intl";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import { withTina } from "tinacms";
import { AppHead } from "../common/components/AppHead";
import { CmsEnabler } from "../common/components/CmsEnabler";
import { FloatingCTA } from "../common/components/FloatingCTA";
import Footer from "../common/components/Footer";
import "../common/lib/api";
import { LOCALE_OPTIONS } from "../common/lib/api/base";
import { WindowContextProvider } from "../common/lib/hooks/useWindow";
import initAuth from "../common/lib/initAuth";
import { Web3Provider } from "../common/lib/web3";
import txt from "../common/translations";
import { usePlugins } from "../forms/plugins";
import "../scripts/wdyr";
import "../styles/globals.scss";

initAuth();

const DayJsLocaleSetter = () => {
  const { locale } = useRouter();
  useEffect(() => {
    dayjs.locale(locale);
  }, [locale]);
  return null;
};

function MyApp({ Component, pageProps, router }: AppProps) {
  usePlugins();
  // Airtable initialization (doesnt need/cant be on useeffect)
  Airtable.configure({
    endpointUrl: "https://api.airtable.com",
    apiKey: process.env.NEXT_PUBLIC_AIRTABLE_API_KEY,
  });
  return (
    <NextIntlProvider messages={pageProps.messages}>
      <Web3Provider>
        <DayJsLocaleSetter />
        <ThemeProvider theme={{}}>
          <Head>
            <link rel="stylesheet" href="/fonts/bebas/stylesheet.css" />
          </Head>
          <AppHead {...{ router }} />
          <WindowContextProvider>
            <CmsEnabler />
            <Component {...pageProps} />
            <ToastContainer />
            <Footer {...{ router }} />
            <FloatingCTA />
          </WindowContextProvider>
        </ThemeProvider>
      </Web3Provider>
    </NextIntlProvider>
  );
}

const EnhancedApp = withTina(MyApp, {
  enabled: false,
  sidebar: true,
});

// @ts-ignore
EnhancedApp.getInitialProps = async ({ ctx }: any) => {
  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  // Fetch global site settings from Strapi
  // Pass the data to our page via props
  return {
    pageProps: { messages: txt[ctx.locale as LOCALE_OPTIONS] },
  };
};
export default EnhancedApp;
