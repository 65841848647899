import { initializeApp, getApps } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

// Initialize Firebase
if (!getApps().length) {
  const app = initializeApp(
    JSON.parse(process.env.NEXT_PUBLIC_FIREBASE_CONFIG as string)
  );
  if (process.env.NODE_ENV !== "production") {
    connectFirestoreEmulator(getFirestore(app), "localhost", 8080);
  }
}
