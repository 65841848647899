import { RiDiscordFill } from "@react-icons/all-files/ri/RiDiscordFill";
import { RiInstagramFill } from "@react-icons/all-files/ri/RiInstagramFill";
import { RiTwitterFill } from "@react-icons/all-files/ri/RiTwitterFill";
import dayjs from "dayjs";
import Link from "next/link";
import { useRouter } from "next/router";
import { MouseEventHandler } from "react";
import { useTranslations } from "use-intl";
import { useBreakpoint } from "../../lib/hooks/useWindow";
import { handleChangeLocale } from "../../lib/utils";
import { makeLink } from "../Header";
import { Copyright, CopyText, Footer, Sections } from "./styles";

const FooterComponent: React.FC<{ router: ReturnType<typeof useRouter> }> = ({
  router,
}) => {
  const t = useTranslations("Footer");
  const th = useTranslations("Header");
  const { asPath, locale } = router;
  const isMedium = useBreakpoint("MEDIUM");

  return (
    <Footer>
      <Copyright>
        <div className="logo">
          <Link href={makeLink("/")}>
            <a>
              <img
                src={
                  isMedium
                    ? "/logo_horizontal_black.svg"
                    : "/spaceship_icon_dynamic.svg"
                }
                className="icon"
              />
            </a>
          </Link>
        </div>
        <div className="icons" style={{ display: "flex" }}>
          <a
            target="_blank"
            referrerPolicy="no-referrer"
            href="https://instagram.com/spaceshipnft.eth?utm_medium=copy_link"
          >
            <RiInstagramFill />
          </a>
          <a
            target="_blank"
            referrerPolicy="no-referrer"
            href="https://twitter.com/spaceshipnft"
          >
            <RiTwitterFill />
          </a>
          <a
            target="_blank"
            referrerPolicy="no-referrer"
            href={"https://discord.gg/6VncfT6WAk"}
          >
            <RiDiscordFill />
          </a>
        </div>
      </Copyright>
      <CopyText>{t("copyright", { year: dayjs().year() })}</CopyText>
      <Sections>
        <div>
          <h3>{t("indexes")}</h3>
          <Link href={makeLink("/arts")}>
            <a>
              <span>{th("artworks")}</span>
            </a>
          </Link>
          <Link href={makeLink("/artists")}>
            <a>
              <span>{th("artists")}</span>
            </a>
          </Link>
        </div>
        <div>
          <h3>{t("info")}</h3>
          <Link href={makeLink("/news")}>
            <a>
              <span>{th("news")}</span>
            </a>
          </Link>
          <Link href={makeLink("/about")}>
            <a>
              <span>{th("aboutUs")}</span>
            </a>
          </Link>
        </div>
        <div>
          <h3>{t("other")}</h3>
          <Link href={asPath} locale={locale === "en" ? "pt" : "en"}>
            <a onClick={handleChangeLocale}>
              <span>{th("otherLanguage")}</span>
            </a>
          </Link>
          <Link href={makeLink("/contact")}>
            <a>
              <span>{th("contact")}</span>
            </a>
          </Link>
        </div>
      </Sections>
    </Footer>
  );
};

export default FooterComponent;
