import { VscGlobe } from "@react-icons/all-files/vsc/VscGlobe";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment, HTMLAttributes, useEffect, useState } from "react";
import {
  LOCALE_LINK_OVERRIDES,
  LOCALE_OPTIONS,
  supportedLocales,
} from "../../lib/api/base";
import { useBreakpoint } from "../../lib/hooks/useWindow";
import { handleChangeLocale } from "../../lib/utils";
import { Hamburger, LanguageMenu, Navbar, Socials } from "./styles";

export const makeLink = (
  src: string,
  path: string = process.env.NEXT_PUBLIC_BASE_URL || "https://spaceshipnft.com"
) => {
  // Only web has this variable so we can use it to id if its News or Web
  return !process.env.NEXT_PUBLIC_OPENSEA_URL ? `${path}${src}` : src;
};

type LINK_TYPE = {
  title: string;
  url: string;
  mobileOnly?: true;
  includeLocale?: true;
};

const DEFAULT_LINKS: LINK_TYPE[] = [
  {
    url: "/",
    title: "home",
    mobileOnly: true,
  },
  {
    url: "/artists",
    title: "artists",
  },
  {
    url: "/arts",
    title: "artworks",
  },
  {
    url: "/about",
    title: "aboutUs",
  },
  {
    url: "/meta",
    title: "meta",
  },
  {
    url: "/news",
    title: "news",
    includeLocale: true,
  },
];

type HEADER_PROPS = HTMLAttributes<HTMLDivElement> & {
  links?: LINK_TYPE[];
  localeLinkOverrides?: LOCALE_LINK_OVERRIDES;
  router?: ReturnType<typeof useRouter>;
};

const Header: React.FC<HEADER_PROPS> = ({
  links = DEFAULT_LINKS,
  localeLinkOverrides,
  style,
  className,
  router,
}) => {
  const [open, setOpen] = useState(false);
  const t = useTranslations("Header");
  const isMedium = useBreakpoint("MEDIUM");
  useEffect(() => {
    document.body.style.overflow = open && !isMedium ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open, isMedium]);
  const Dot = isMedium && <span className="dot">•</span>;
  const hookRouter = useRouter();
  const { locale: incLocale, asPath } = hookRouter || router;
  const locale = (incLocale || "en") as LOCALE_OPTIONS;
  const getTabIndex = (fallback?: number) => {
    if (isMedium) return fallback;
    return open ? 0 : -1;
  };
  return (
    <Navbar style={style} className={className}>
      <div>
        <Link href={makeLink("/")}>
          <a>
            <img
              src={"/logo_horizontal.svg"}
              height={isMedium ? 40 : 35}
              alt={t("logo") as string}
              aria-label={t("logo") as string}
              title={t("logo") as string}
              style={{ zIndex: 3, position: "relative" }}
            />
          </a>
        </Link>
      </div>
      <Hamburger
        tabIndex={0}
        aria-label={t("hamburger") as string}
        className={!isMedium && open ? "open" : ""}
        onClick={() => {
          setOpen(!open);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.keyCode === 13 || e.key === "Space")
            setOpen(!open);
        }}
      >
        <div></div>
        <div></div>
        <div></div>
      </Hamburger>
      <Socials className={!isMedium && open ? "open" : ""}>
        {isMedium ? (
          <LanguageMenu
            aria-label={t("languageMenu") as string}
            title={t("languageMenu") as string}
            tabIndex={getTabIndex(0)}
          >
            <VscGlobe
              style={{ marginTop: 2, marginRight: 2, fontSize: "1.25em" }}
            />
            <span style={{ marginRight: 6 }}>{locale.toUpperCase()}</span>
            <div className="menu">
              {supportedLocales.map((locale) => {
                let link = asPath;
                if (
                  localeLinkOverrides &&
                  localeLinkOverrides[locale] !== undefined
                ) {
                  link = localeLinkOverrides[locale] || asPath;
                }
                return (
                  <Link href={link} locale={locale} key={locale}>
                    <a tabIndex={getTabIndex()} onClick={handleChangeLocale}>
                      <div>{t(`lang.${locale}`)}</div>
                    </a>
                  </Link>
                );
              })}
            </div>
          </LanguageMenu>
        ) : (
          <div className="mobile-link">
            <Link href={asPath} locale={locale === "en" ? "pt" : "en"}>
              <a onClick={handleChangeLocale}>{t("otherLanguage")}</a>
            </Link>
          </div>
        )}
        {Dot}
        {links.map((link) => {
          if (link.mobileOnly && isMedium) return null;
          const NextLink = (
            <Link href={makeLink(link.url)}>
              <a tabIndex={getTabIndex()}>{t(link.title)}</a>
            </Link>
          );
          const AnchorLink = (
            <a
              tabIndex={getTabIndex()}
              href={`${process.env.NEXT_PUBLIC_BASE_URL}/${link.url}`}
            >
              {t(link.title)}
            </a>
          );
          return (
            <Fragment key={link.url}>
              <h3>{link.includeLocale ? AnchorLink : NextLink}</h3>
              {Dot}
            </Fragment>
          );
        })}
        <a
          href="https://discord.gg/6VncfT6WAk"
          style={{ marginTop: 2 }}
          target="_blank"
          referrerPolicy="no-referrer"
        >
          <img src={"/discord.svg"} style={{ height: "1em" }} alt="Discord" />
        </a>
      </Socials>
    </Navbar>
  );
};

export default Header;
export { Header };
