import React, { FC } from "react";
import styled, { css } from "styled-components";
import { BLACK_BG, WHITE_BG } from "../../styles/mixins";

export type BUTTON_VARIANTS =
  | "white"
  | "pink"
  | "black"
  | "white-filled"
  | "black-filled";

export type BUTTON_PROPS = {
  $variant: BUTTON_VARIANTS;
  $hasIcon: boolean;
};

const hasIcon = css`
  padding: 6px 1.2ch;
  padding-right: 5ch;
`;

const shadow = (inset = true) =>
  inset
    ? css`
        &:hover {
          box-shadow: 0 0 12.1px rgb(255 255 255 / 48%),
            0 0 12.2px rgb(65 120 255 / 24%),
            inset 0 0 11.1px rgb(219 219 219 / 58%);
        }
      `
    : css`
        &:hover {
          box-shadow: 0 0 12.1px rgb(255 255 255 / 48%),
            0 0 12.2px rgb(65 120 255 / 24%);
        }
      `;

export const StyledInput = styled.input<BUTTON_PROPS>`
  ${(props) => (!props.$hasIcon ? `justify-content: center;` : ``)}
  outline: none;
  border: 2px solid transparent;
  border-color: currentColor;
  background: none;
  border-radius: 6px;
  padding: 0.35em 0.65em;
  font-size: 0.9em;
  font-weight: 600;
  font-family: "Bebas Neue Pro Expanded";
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  div {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    right: 0.65ch;
    border-left: inherit;
    padding-left: 0.5ch;
  }
  ${(props) => shadow(props.$variant !== "black-filled")}
  ${(props) => {
    switch (props.$variant) {
      case "white":
        return "color: white;";
      case "white-filled":
        return `
       color: ${BLACK_BG};
       background-color: ${WHITE_BG};
       border-color: ${WHITE_BG};
      `;
      case "black":
        return `color: ${BLACK_BG};`;
      case "black-filled":
        return `
       color: ${WHITE_BG};
       background-color: ${BLACK_BG};
       border-color: ${BLACK_BG};
      `;
    }
  }}
  ${(props) => (props.$hasIcon ? hasIcon : "")}
`;
