import { useMouseWheel, useWindowScroll } from "react-use";
import { useTranslations } from "use-intl";
import { Wrapper } from "./styles";

const FloatingCTA: React.FC = () => {
  const { y: scroll } = useWindowScroll();
  const t = useTranslations("Home");
  if (scroll < 400) return null;
  return (
    <Wrapper>
      <button
        onClick={() => window.scrollTo(0, 0)}
        aria-label={t("to_top") as string}
        title={t("to_top") as string}
      >
        <span>🚀</span>
      </button>
    </Wrapper>
  );
};

export { FloatingCTA };
