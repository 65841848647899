import {
  SUPPORTED_NETWORKS,
  chainIdNameMap,
  CHAIN_IDS,
  chainNameToNetworkConfig,
  nameChainIdMap,
  toChainId,
} from "../../solidity";
import SpaceshipCollectionJSON from "../../solidity/artifacts/contracts/SpaceshipCollection.sol/SpaceshipCollection.json";
import CollectionFactoryJSON from "../../solidity/artifacts/contracts/CollectionFactory.sol/CollectionFactory.json";
import ERC1155JSON from "../../solidity/artifacts/@openzeppelin/contracts/token/ERC1155/ERC1155.sol/ERC1155.json";
import { ethers, providers } from "ethers";
import { createContext, useContext, useEffect, useState } from "react";
import type { Web3Auth } from "@web3auth/web3auth";

const ExpectedNetwork = process.env
  .NEXT_PUBLIC_CHAIN_NAME as SUPPORTED_NETWORKS;

export const factories = {
  collectionFactory: (signer: ethers.Signer) =>
    new ethers.ContractFactory(
      CollectionFactoryJSON.abi,
      CollectionFactoryJSON.bytecode,
      signer
    ),
};

export const abis = {
  spaceshipCollection: SpaceshipCollectionJSON.abi,
  collectionFactory: CollectionFactoryJSON.abi,
};

export { SpaceshipCollectionJSON, CollectionFactoryJSON, ERC1155JSON };

const switchNetwork = async (
  web3: providers.Web3Provider,
  networkName: SUPPORTED_NETWORKS = ExpectedNetwork
) => {
  try {
    await web3.send("wallet_switchEthereumChain", [
      { chainId: toChainId(nameChainIdMap[networkName]) },
    ]);
  } catch (error: any) {
    if (error.code === 4902) {
      try {
        await web3.send("wallet_addEthereumChain", [
          chainNameToNetworkConfig[networkName],
        ]);
      } catch (error: any) {
        alert(error.message);
      }
    }
  }
};

export const useNetwork = (
  requireNetwork: SUPPORTED_NETWORKS = ExpectedNetwork
) => {
  const { provider } = useWeb3();
  const returnWhenWrongNetwork = {
    switchNetwork,
    currentNetworkName: undefined,
  };
  const noop: typeof switchNetwork = async () => {};
  // No wallet/web3 not enabled => no network
  if (!provider || !provider.network)
    return {
      switchNetwork: noop,
      currentNetworkName: undefined,
    };
  const currentNetworkChainId = provider.network.chainId;
  const currentNetworkName =
    chainIdNameMap[currentNetworkChainId.toString() as CHAIN_IDS];
  if (
    !currentNetworkName ||
    (requireNetwork && currentNetworkName !== requireNetwork)
  )
    return returnWhenWrongNetwork;

  if (
    currentNetworkName === "localhost" &&
    process.env.NODE_ENV !== "development"
  )
    return returnWhenWrongNetwork;

  return { currentNetworkName, switchNetwork };
};

export const getExplorerLink = (
  id: string | number,
  type: string = "address"
) => {
  const blockExplorerBaseUrl: string[] =
    chainNameToNetworkConfig[ExpectedNetwork].blockExplorerUrls;
  if (!blockExplorerBaseUrl[0]) return null;
  return `${blockExplorerBaseUrl}/${type}/${id}`;
};

const WEB3_CONTEXT_DEFAULT: {
  login: () => Promise<void>;
  logout: () => Promise<void>;
  getAccount: () => Promise<ethers.providers.JsonRpcSigner | undefined>;
  getUserInfo: () => Promise<any>;
  isLoggedIn: boolean;
  provider: ethers.providers.Web3Provider | null;
} = {
  login: async () => {},
  logout: async () => {},
  getAccount: async () => {
    return undefined;
  },
  getUserInfo: async () => {},
  isLoggedIn: false,
  provider: null,
};

const Web3Context = createContext(WEB3_CONTEXT_DEFAULT);

export const Web3Provider: React.FC = ({ children }) => {
  const [web3auth, setWeb3auth] = useState<Web3Auth | null>(null);
  const [provider, setProvider] =
    useState<ethers.providers.Web3Provider | null>(null);

  useEffect(() => {
    (async () => {
      const [
        { ADAPTER_EVENTS, CHAIN_NAMESPACES },
        { Web3Auth },
        { OpenloginAdapter },
        { TorusWalletConnectorPlugin },
        { TorusWalletAdapter },
      ] = await Promise.all([
        import("@web3auth/base"),
        import("@web3auth/web3auth"),
        import("@web3auth/openlogin-adapter"),
        import("@web3auth/torus-wallet-connector-plugin"),
        import("@web3auth/torus-evm-adapter"),
      ]);

      const whiteLabel = {
        name: "Spaceship Foundation",
        theme: {
          isDark: false,
          colors: {
            torusBrand1: "#191b1f",
          },
        },
        logoDark: "https://spaceshipnft.com/spaceship_icon.png",
        logoLight: "https://spaceshipnft.com/spaceship_icon_dynamic.svg",
        topupHide: true,
        disclaimerHide: true,
        featuredBillboardHide: true,
      };

      // it will add/update  the torus-evm adapter in to web3auth class
      const subscribeAuthEvents = (web3auth: any) => {
        // Can subscribe to all ADAPTER_EVENTS and LOGIN_MODAL_EVENTS
        web3auth.on(ADAPTER_EVENTS.CONNECTED, async (data: unknown) => {
          let newProvider = new ethers.providers.Web3Provider(
            web3auth.provider
          );
          console.log(
            "Yeah!, you are successfully logged in",
            newProvider,
            data
          );
          await newProvider._networkPromise;
          setProvider(newProvider);
        });

        web3auth.on(ADAPTER_EVENTS.CONNECTING, () => {
          console.log("connecting");
        });

        web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
          console.log("disconnected");
          setProvider(null);
        });

        web3auth.on(ADAPTER_EVENTS.ERRORED, (error: any) => {
          console.error(
            "some error or user has cancelled login request",
            error
          );
          setProvider(null);
        });
      };

      const init = async () => {
        try {
          const web3AuthCtorParams = {
            uiConfig: {
              theme: "dark" as const,
              appLogo: "https://spaceshipnft.com/spaceship_icon_dynamic.svg",
            },
            clientId:
              "BI0CHp_q9_y4FEdv6ux-jBqQtwxb8EPARRCb9TqvL0ZRe3JaZ3RMu4aPYTYizzwpzDSU26uTJ1rQOXdlpaA8drI",
            chainConfig: {
              chainNamespace: CHAIN_NAMESPACES.EIP155,
              chainId:
                chainNameToNetworkConfig[
                  process.env
                    .NEXT_PUBLIC_CHAIN_NAME as keyof typeof chainNameToNetworkConfig
                ].chainId,
              // rpcTarget:
              //   "https://polygon-mainnet.infura.io/v3/5554892250224defb6c817ddb2755733", // This is the testnet RPC we have added, please pass on your own endpoint while creating an app
            },
          };
          const web3auth = new Web3Auth(web3AuthCtorParams);
          const openloginAdapter = new OpenloginAdapter({
            adapterSettings: {
              clientId:
                "BI0CHp_q9_y4FEdv6ux-jBqQtwxb8EPARRCb9TqvL0ZRe3JaZ3RMu4aPYTYizzwpzDSU26uTJ1rQOXdlpaA8drI",
              network: "mainnet",
              uxMode: "popup",
              whiteLabel: whiteLabel as any,
            },
          });
          const torusWalletAdapter = new TorusWalletAdapter({
            adapterSettings: {
              buttonPosition: "bottom-left",
              apiKey: process.env.NEXT_PUBLIC_TORUS_KEY,
            },
            loginSettings: {
              verifier: "google",
            },
            initParams: {
              useWalletConnect: true,
              whiteLabel,
            },
          });
          const torusPlugin = new TorusWalletConnectorPlugin({
            torusWalletOpts: {
              apiKey: process.env.NEXT_PUBLIC_TORUS_KEY,
            },
            walletInitOptions: {
              whiteLabel,
              useWalletConnect: true,
              // enableLogging: true,
            },
          });
          web3auth.configureAdapter(openloginAdapter);
          web3auth.configureAdapter(torusWalletAdapter);
          await web3auth.addPlugin(torusPlugin);
          subscribeAuthEvents(web3auth);
          await web3auth.initModal({});
          setWeb3auth(web3auth);
        } catch (error) {
          console.error(error);
        }
      };

      init();
    })();
  }, []);

  const login = async () => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    const incomingProvider = await web3auth.connect();
    if (incomingProvider) {
      const web3 = new ethers.providers.Web3Provider(incomingProvider);
      await web3._networkPromise;
      setProvider(web3);
    }
  };

  const getUserInfo = async () => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    const user = await web3auth.getUserInfo();
  };

  const logout = async () => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    await web3auth.logout();
    setProvider(null);
  };

  const getAccount = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      return;
    }
    await provider.send("eth_requestAccounts", []);

    const signer = await provider.getSigner();
    return signer;
  };
  return (
    <Web3Context.Provider
      value={{
        login,
        logout,
        getAccount,
        getUserInfo,
        isLoggedIn: !!provider,
        provider,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};

export const useWeb3 = () => useContext(Web3Context);

export * as typechain from "../../solidity/typechain";
export * from "../../solidity";
