export type SUPPORTED_NETWORKS = "localhost" | "mumbai" | "matic";
export type DEPLOYED_CONTRACTS = "factory" | "collection";
export type CHAIN_IDS = "31337" | "80001" | "137";

export type NETWORK_ADDRESS_MAP = {
  [key in SUPPORTED_NETWORKS]: {
    [key in DEPLOYED_CONTRACTS]?: string;
  };
};

export type CHAINID_TO_NETWORK_NAME = {
  [key in CHAIN_IDS]: SUPPORTED_NETWORKS;
};

export type NETWORK_NAME_TO_CHAIN_ID = {
  [key in SUPPORTED_NETWORKS]: CHAIN_IDS;
};

export type CHAIN_NAME_TO_CONFIG = {
  [key in SUPPORTED_NETWORKS]: any;
};

export type NamedAccounts = {
  [key in "spaceship"]: string;
};
import addrs from "./addresses.json";
const typedAddresses = addrs as NETWORK_ADDRESS_MAP;

export const chainIdNameMap: CHAINID_TO_NETWORK_NAME = {
  137: "matic",
  31337: "localhost",
  80001: "mumbai",
};
export const toChainId = (inc: number | string) => "0x" + (+inc).toString(16);

export const nameChainIdMap = Object.fromEntries(
  Object.entries(chainIdNameMap).map(([key, val]) => [val, key])
) as NETWORK_NAME_TO_CHAIN_ID;

export const chainNameToNetworkConfig = {
  localhost: {
    chainId: toChainId(31337),
    chainName: "Local Hardhat",
    rpcUrls: ["https://localhost:8545"],
    nativeCurrency: {
      name: "Ethereum",
      symbol: "ETH",
    },
    blockExplorerUrls: [],
  },
  matic: {
    chainName: "Polygon",
    chainId: toChainId(137),
    rpcUrls: [
      "https://polygon-mainnet.infura.io/v3/5554892250224defb6c817ddb2755733",
    ],
    nativeCurrency: {
      name: "Matic",
      symbol: "Matic",
      decimals: 18,
    },
    blockExplorerUrls: ["https://polygonscan.com"],
  },
  mumbai: {
    chainId: toChainId(80001),
    chainName: "Mumbai (Polygon Testnet)",
    rpcUrls: [
      "https://polygon-mumbai.infura.io/v3/5554892250224defb6c817ddb2755733",
    ],
    nativeCurrency: {
      name: "Matic",
      symbol: "Matic",
      decimals: 18,
    },
    blockExplorerUrls: ["https://mumbai.polygonscan.com"],
  },
};
export const addresses = typedAddresses;
