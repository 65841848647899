import { useCallback, useMemo, useState } from "react";

const useCloudinaryWidget = ({
  name = process.env.NEXT_PUBLIC_CLOUDINARY_NAME,
} = {}) => {
  const [url, setUrl] = useState<string | null>(null);
  const widgetRef = useMemo(
    () =>
      typeof window !== "undefined" &&
      // @ts-ignore
      window.cloudinary.createUploadWidget(
        {
          cloudName: name,
          uploadPreset: "unsigned",
        },
        (error: any, result: any) => {
          if (!error && result && result.event === "success") {
            setUrl(result.info.secure_url);
          }
        }
      ),
    [typeof window !== "undefined"]
  );
  const pick = useCallback(() => widgetRef.open(), [widgetRef]);
  return { pick, url };
};

export { useCloudinaryWidget };
