import styled, { css } from "styled-components";
import {
  biggerFirstLetter,
  BLACK_BG,
  medium,
  mobileOnly,
  px,
  WHITE_BG,
} from "../../styles/mixins";
export const Navbar = styled.nav`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  margin-bottom: 20px;
  ${medium(css`
    padding: 15px 30px;
    margin-bottom: 30px;
  `)}
`;

export const asIcon = (el: any) => styled(el)`
  position: relative;
  z-index: 2;
  &:hover {
    filter: drop-shadow(0px 0px 3px rgba(255 255 255 / 0.8));
  }
`;

const SIZE = 22;
const DASH_SIZE = 2;

export const Hamburger = styled.div.attrs({
  role: "button",
})`
  position: relative;
  z-index: 2;
  margin-right: 1em;
  ${medium(css`
    display: none;
  `)}
  height: ${px(SIZE)};
  width: ${px(SIZE)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > div {
    width: ${px((SIZE - DASH_SIZE) * Math.sqrt(2))};
    height: ${px(DASH_SIZE)};
    border-radius: ${px(DASH_SIZE)};
    background-color: white;
    transition: transform 0.1s linear;
    @media (prefers-reduced-motion) {
      transition: none;
    }
    &:not(:nth-child(2)) {
      transform-origin: right;
    }
  }
  &.open {
    > div {
      &:first-child {
        transform: rotate(-45deg);
      }
      &:nth-child(2) {
        transform: translateX(4px) rotate(-45deg);
      }
      &:last-child {
        transform: rotate(45deg);
      }
    }
  }
`;

export const LanguageMenu = styled.div.attrs({
  role: "button",
})`
  position: relative;
  display: flex;
  align-items: center;
  &:focus,
  &:hover,
  &:focus-within {
    .menu {
      visibility: visible;
    }
  }
  .menu {
    visibility: hidden;
    position: absolute;
    top: calc(100%);
    border-radius: 4px;
    right: 50%;
    transform: translateX(50%);
    background-color: ${BLACK_BG};
    color: ${WHITE_BG};
    padding: 0.5em;
  }
`;

export const Socials = styled.div`
  align-items: center;
  z-index: 1;
  height: 50px;
  .dot {
    font-size: 1.25em;
  }
  h3 {
    ${biggerFirstLetter("1em")}
    position: relative;
    font-family: "Bebas Neue Pro Expanded";
    margin: 0 0.2em;
    letter-spacing: 1px;
    a {
      font-family: inherit;
      color: inherit;
      text-decoration: none;
      &:hover {
        border-bottom: 2px solid currentColor;
      }
    }
  }
  img {
    margin-left: 0.2em;
  }
  // Mobile takeover
  ${mobileOnly(css`
    transition: transform 0.25s linear;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.5em;
    background-color: ${BLACK_BG};
    > h3 + h3 {
      margin-top: 0.6em;
    }
    img {
      margin-top: 1em;
    }
  `)}
  ${medium(css`
    position: relative;
    display: flex;
  `)}

  &.open {
    transform: translateY(100%);
  }
  .mobile-link {
    position: absolute;
    top: 72px;
    a {
      font-family: "Bebas Neue Pro Expanded";
    }
  }
`;
