import { forwardRef, HTMLAttributes } from "react";
import { IconType } from "@react-icons/all-files/lib";
import { BUTTON_VARIANTS as B, StyledInput } from "./styles";
export type BUTTON_VARIANTS = B;
type BUTTON_COMPONENT_PROPS = {
  title: string;
  variant?: B;
  icon?: IconType;
  submit?: true;
  className?: string;
  onClick?: (e: any) => any;
  style?: any;
};

const Button = forwardRef<HTMLButtonElement, BUTTON_COMPONENT_PROPS>(
  (props, ref) => {
    const {
      title,
      variant = "white",
      className,
      icon: Icon,
      submit,
      ...restProps
    } = props;
    if (submit) {
      return (
        <StyledInput
          $hasIcon={!!Icon}
          $variant={variant}
          name={title}
          className={className}
          value={title}
          type="submit"
          {...restProps}
        ></StyledInput>
      );
    } else {
      return (
        <StyledInput
          $hasIcon={!!Icon}
          $variant={variant}
          name={title}
          as="button"
          className={className}
          {...restProps}
        >
          {title}
          {Icon && (
            <div>
              <Icon />
            </div>
          )}
        </StyledInput>
      );
    }
  }
);

export default Button;
export { Button };
