import { FaPlus } from "@react-icons/all-files/fa/FaPlus";
import { FaTrash } from "@react-icons/all-files/fa/FaTrash";
import { useEffect, useState } from "react";
import Select from "react-select";
import { Field, FieldRenderProps } from "tinacms";
import Button from "../../../common/components/Button";
import { useCloudinaryWidget } from "../../../common/components/ImageUploader";
import { Art, CAROUSEL_ITEM, TYPE_ARRAY } from "../../../common/lib/api/base";
import { toNatural } from "../../../common/lib/utils";
import { BLACK_BG } from "../../../common/styles/mixins";
import CS, { FEATURED_ART_INPUT_DATA } from "../CustomSelect";

export type CAROUSEL_INPUT_DATA = CAROUSEL_ITEM[];

const CustomSelect = CS.Component;

type CAROUSEL_SELECT_PROPS<T> = FieldRenderProps<CAROUSEL_INPUT_DATA>;

type CAROUSEL_ITEM_PROPS<T> = CAROUSEL_ITEM &
  Pick<CAROUSEL_SELECT_PROPS<T>, "input" | "meta"> & {
    index: number;
    onChange: (value: CAROUSEL_ITEM | null, index: number) => any;
    data: Array<{ label: string; value: Art }>;
  };

const CarouselSelectItem = <T extends any>({
  type,
  content,
  input,
  index,
  meta,
  onChange,
  data,
}: CAROUSEL_ITEM_PROPS<T>) => {
  const makeOption = (value: typeof type = "post") => ({
    value,
    label: toNatural(value),
  });
  const onTypeChange = (
    data: {
      value: typeof type;
      label: string;
    } | null
  ) => {
    if (!data) return;
    let content = {};
    switch (data.value) {
      // case "art":
      //   content = {
      //     art_id: "",
      //   };
      //   break;
      case "image":
        content = {
          title: "",
          description: "",
          image_link: "",
          image_alt: "",
          article_link: "",
        };
        break;
      case "post":
        content = {
          title: "",
          description: "",
          article_link: "",
        };
        break;
    }
    onChange({ content, type: data.value }, index);
  };
  // Image upload logic
  const { url, pick } = useCloudinaryWidget();
  useEffect(() => {
    if (url && url.length > 0) {
      onChange(
        {
          type,
          content: {
            ...content,
            image_link: url,
          },
        },
        index
      );
    }
  }, [url]);
  const imageUrl = url || content.image_link;
  const entries = Object.entries(content || {});
  const renderEntries = ([key, val]: [string, any]) => {
    if (key === "image_link") {
      return (
        <div key={key}>
          {imageUrl && imageUrl.length > 0 && (
            <div style={{ height: 80, width: 80 }}>
              <img
                src={imageUrl}
                style={{
                  objectFit: "cover",
                  height: "100%",
                  width: "100%",
                }}
              />
            </div>
          )}
          <Button
            title="Selecionar imagem"
            onClick={pick}
            variant="black-filled"
          />
        </div>
      );
    }
    if (key === "art") return null;
    if (key === "art_id") {
      let value = {
        label: "Selecione",
        value: null,
      } as FEATURED_ART_INPUT_DATA<Art | null>;
      if (val) {
        const found = data.find((item) => item.value.id === val);
        if (found) value = found;
      }
      return (
        <CustomSelect
          {...{
            input: {
              ...input,
              value,
              onChange: ({ target: { value } }) => {
                onChange(
                  {
                    type,
                    content: {
                      art_id: value.id,
                      art: value,
                    },
                  },
                  index
                );
              },
            },
            meta: {
              ...meta,
              initial: value,
            },
            field: {
              data,
              label: "Selecione arte",
              name: key,
            },
          }}
          key={key}
        />
      );
    }
    return (
      <div key={key}>
        <label
          htmlFor={`${key}${index}`}
          style={{ fontSize: "0.7em", opacity: 0.6 }}
        >
          {toNatural(key)}
        </label>
        <textarea
          id={`${key}${index}`}
          rows={val.length > 30 ? 5 : 2}
          key={key}
          name={key}
          value={val}
          placeholder={toNatural(key)}
          style={{ display: "block", width: "100%" }}
          onChange={({ target: { value } }) => {
            onChange(
              {
                type,
                content: {
                  ...content,
                  [key]: value.replace("-", "\u2011"),
                },
              },
              index
            );
          }}
        />
      </div>
    );
  };
  return (
    <div>
      <h3 style={{ textTransform: "none" }}>
        Posição {index + 1}{" "}
        <FaTrash
          style={{ float: "right" }}
          onClick={() => {
            if (window.confirm("Do you wish to delete this entry?")) {
              onChange(null, index);
            }
          }}
        />
      </h3>
      <Select
        options={TYPE_ARRAY.map(makeOption)}
        value={makeOption(type)}
        onChange={onTypeChange}
      />
      <div>{entries.map(renderEntries)}</div>
    </div>
  );
};

const CarouselSelect = <T extends any = CAROUSEL_ITEM>(
  props: CAROUSEL_SELECT_PROPS<T>
) => {
  const { input, meta, ...restProps } = props;
  const field = props.field as Field & {
    data?: Array<{ label: string; value: Art }>;
  };
  const [data, setData] = useState((meta.initial || []) as CAROUSEL_ITEM[]);
  const handleItemChange = (value: CAROUSEL_ITEM | null, index: number) => {
    setData((prev) => {
      const processed = [...prev];
      // Value === null => Delete
      if (value === null) {
        processed.splice(index, 1);
      } else {
        processed.splice(index, 1, value);
      }
      return processed;
    });
  };
  useEffect(() => {
    input.onChange({
      target: {
        value: data,
        id: field.name,
        name: field.name,
      },
    });
  }, [data]);
  return (
    <div style={{ color: BLACK_BG }}>
      {data.map((item, index) => (
        <CarouselSelectItem
          {...{
            ...item,
            input,
            index,
            meta,
            onChange: handleItemChange,
            data: field.data || [],
          }}
          key={index}
        />
      ))}
      <FaPlus
        onClick={() =>
          setData(
            data.concat({
              type: "post",
              content: {
                title: "",
                description: "",
                article_link: "",
              },
            })
          )
        }
      />
    </div>
  );
};

export default {
  Component: CarouselSelect,
  name: "carousel-select",
};
