import { init } from "next-firebase-auth";
import devServiceAccount from "../service-account.dev.json";
import prodServiceAccount from "../service-account.prod.json";
//@ts-ignore
const initAuth = () => {
  let serviceAccount: any = {};
  if (!process.browser) {
    if (process.env.NODE_ENV === "production") {
      serviceAccount = prodServiceAccount;
    } else {
      serviceAccount = devServiceAccount;
    }
  }
  init({
    loginAPIEndpoint: "/api/login", // required
    logoutAPIEndpoint: "/api/logout", // required
    firebaseAuthEmulatorHost:
      process.env.NODE_ENV !== "production" ? "localhost:9099" : undefined,
    // Required in most cases.
    firebaseAdminInitConfig: {
      credential: {
        projectId: serviceAccount.project_id as string,
        clientEmail: serviceAccount.client_email as string,
        privateKey: serviceAccount.private_key as string,
      },
      databaseURL: `${process.env.FIREBASE_PROJECT_ID}.firebaseio.com`,
    },
    firebaseClientInitConfig: JSON.parse(
      process.env.NEXT_PUBLIC_FIREBASE_CONFIG as string
    ),
    cookies: {
      name: "Spaceship", // required
      // Keys are required unless you set `signed` to `false`.
      // The keys cannot be accessible on the client side.
      keys: [
        process.env.COOKIE_SECRET_CURRENT as string,
        process.env.COOKIE_SECRET_PREVIOUS as string,
      ],
      httpOnly: true,
      maxAge: 12 * 60 * 60 * 24 * 1000, // twelve days
      overwrite: true,
      path: "/",
      sameSite: "strict",
      secure: process.env.NODE_ENV === "production", // set this to false in local (non-HTTPS) development
      signed: true,
    },
  });
};

export default initAuth;
